export const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

export const currency = process.env.GATSBY_CURRENCY;
export const country = "Yorkshire";
export const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "London";
export const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');

export const defaultArea_Newhomes = process.env.GATSBY_DEFAULT_AREA_NEWHOMES ? process.env.GATSBY_DEFAULT_AREA_NEWHOMES : "London";
export const defaultArea_Newhomes_Slug = defaultArea_Newhomes.toLowerCase().replace(/ /g,'-');

export const site_name = process.env.GATSBY_SITE_NAME;
export const hasMyaccount = (process.env.GATSBY_MYACCOUNT || null) === '1' ? true : false;

export const IsAllAgentReview = true;
export const postCodeAddressAPI = "ncZgV7m79UipxWpivISlAw37900";
export const LettingsAdminEmail = true;

export const search_box_placeholder = "Enter a location e.g. Yorkshire";

export const Default_View = "List"

export const footerVatDetails = "Linley and Simpson Group Ltd is registered in England & Wales.<br />Registered office address: 70 St. Mary Axe, London, EC3A 8BE.<br />Registered company number: 08761192. VAT Number: 299705053.<br />Part of Lomond";

export const Mail_Vars = {
    fb_link: "https://www.facebook.com/linleyandsimpsonea/",
    tw_link: "https://twitter.com/linleyandsimpsonea",
    company_phone: "0113 237 0160",
    available_time: "We're available from 9- 5pm Monday to Friday.",
    address: "Troy Mills, Troy Road, Horsforth LS18 5GN."
}

// export const PageLinks = {
//     about: "about-linleyandsimpson",
//     general_enquiry: "general-enquiry",
//     news_insight: `about-linleyandsimpson/insights`,
//     branch_finder: "about-linleyandsimpson/branch-finder",
//     area_cover: "about-linleyandsimpson/areas-we-cover",
//     branch_contact: "contact-us",
//     team_contact: "contact-us",
//     teams: "about-linleyandsimpson/meet-the-team",
//     request_valuation: "property-valuation",
//     privacy_policy: "privacy-policy",
//     book_a_viewing: "book-a-viewing",
//     book_a_valuation: "property-valuation/home-visit-valuation",
//     send_to_friend: "send-to-friend",
//     property_to_rent: "property/to-rent",
//     property_for_sale: "property/for-sale",
//     reviews: "about-linleyandsimpson/customer-reviews",
//     property_management: "landlords/property-management",
//     financial_services: `financial-services`
// }

export const PropertySearchURL = {
    residential: {
        sales:"/property/for-sale/",
        lettings:"/property/to-rent/",
        newhomes: "/new-homes/for-sale/",
        newdevelopments: "/new-developments/for-sale/",
        students:"/property-students/to-rent/",
        investments: "/property-investments/for-sale/"
    },
    commercial: {
        sales:"/commercial-property/for-sale/",
        lettings:"/commercial-property/to-rent/",
    },
    auction: {
        sales:"/auctions/for-sale/"
    }
}

export const PropertyDetailsURL = {
    residential: {
        sales:"/property-for-sale/",
        lettings:"/property-to-rent/",
        newhomes:"/new-homes-for-sale/",
        newdevelopments:"/new-developments-for-sale/"
    },
    commercial: {
        sales:"/commercial-property-for-sale/",
        lettings:"/commercial-property-to-rent/",
    },
    auction: {
        sales:"/auction-for-sale/"
    }
}

export const SearchTitle = {
    residential: {
        sales:"Properties for sale",
        lettings:"Properties to rent",
        newhomes:"New developments",
        investments: "Investment properties for sale",
        students:"Student properties for sale",
    },
    commercial: {
        sales:"Commercial properties for sale",
        lettings:"Commercial properties to rent",
    },
    auction: {
        sales:"Auction properties for sale"
    }
}

export const PopularSearch_Areas = [ 
    { value: "yorkshire", label: "Yorkshire" },
    { value: "harrogate", label: "Harrogate" },
    { value: "headingley", label: "Headingley" },
    { value: "skipton", label: "Skipton" }   
]


export const PopularSearch_Types = [ 
    { value: "house", label: "Houses" },
    { value: "apartment", label: "Apartments" },
    { value: "bungalow", label: "Bungalows" },
    { value: "maisonette", label: "Maisonettes" }   
]

export const sortByList = [ 
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
    { value: "HighestPrice", label: "Highest Price" },
    { value: "LowestPrice", label: "Lowest Price" }   
]

// export const SearchType = {
//     sales:"Buy",
//     lettings:"Rent",
//     newhomes:"New homes"
// }

export const SearchType = {
    sales:"For sale",
    lettings:"To rent",
    newhomes:"New homes",
    newdevelopments:"New developments",
    investments:"Investments",
    students:"Students",
    commercial_sales:"Commercial sales",
    commercial_lettings:"Commercial lettings",
    auction_sales:"Auctions",
    land: "Land"
}

export const FurnishedType = [    
    { value: '', label: 'All' },
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    { value: 'part-furnished', label: 'Part furnished' },
]


export const leadooPageSlot = {
    sell_property:"Sellers",
    landlords:"Landlords",
    sales:"Sale listings",
    lettings:"Rental listings",
    branch_finder:"",
    general_enquiry:"General enquiry",
    valuation:"Valuation",
    home:"Valuation"
}